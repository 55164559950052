const config = {
  ENVIRONMENT_NAME: 'testing',
  forwoodId: {
    URL: 'https://id.testing.riotinto.forwoodsafety.com',
    APP_CLIENT_ID: '1481sh1kktr1mrmj9ti8ibtoai',
    APIGATEWAY: 'https://wzmc3sfhh4.execute-api.us-east-1.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://zuaago3su1.execute-api.us-east-1.amazonaws.com/prod'
  },
  reactApp: {
    ENVIRONMENT_NAME: 'testing',
    VERSION: 'incidents-container-1.8.0',
    HOSTNAME: 'https://incidents.testing.riotinto.forwoodsafety.com',
    INCIDENTS_ACCESS_ROLE: 'IncidentsAccess',
    CREATE_INCIDENT_URL: 'https://iframe.testing.riotinto.forwoodsafety.com/node/add/isi',
    INCIDENT_LIST_URL: 'https://iframe.testing.riotinto.forwoodsafety.com/isi-list',
    IFRAME_ORIGIN_URL: 'https://iframe.testing.riotinto.forwoodsafety.com'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;

